<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex flex-1 pb-16 sm:pt-8 sm:pb-24 flex-col lg:flex-none ">
      <div class="px-4 sm:px-12 h-16 flex justify-between items-center ">
        <Button type="text" @click="goToLanding" color="black">
          <template #icon-before>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </template>
          Retour
        </Button>
        <p class="text-sm text-gray-400">
          Pas encore de compte ?
          <Button type="link" @click="goToRegister">S'inscrire</Button>
        </p>
      </div>
      <div class="flex-1 flex flex-col justify-center px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              Connectez-<span class="text-primary-500">vous</span>
              <Emoji class="pl-1" data="✌️"></Emoji>
            </h2>
            <p class="pt-4 text-gray-400 tracking-wide font-light">
              Vos informations sont stockées de manière sécurisée pour protéger votre
              confidentialité.
            </p>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <SchemaFormWithValidation
                ref="loginForm"
                class="space-y-6"
                :schema="loginFormSchema"
                v-model="loginFormData"
                @submit="login"
              >
                <template #afterForm>
                  <div class="flex items-center justify-between">
                    <Checkbox
                      name="rememberMe"
                      label="Se souvenir de moi"
                      v-model="loginFormData.rememberMe"
                    ></Checkbox>
                    <Button type="link" @click="goToForgotPassword">Mot de passe oublié</Button>
                  </div>

                  <div>
                    <Button type="submit" color="success" :loading="loading">Se connecter</Button>
                    <p class="text-xs text-gray-300 font-light text-center mt-4">
                      <svg
                        class="h-4 w-4 inline"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        />
                      </svg>
                      Vos informations sont sécurisées
                    </p>
                  </div>
                </template>
              </SchemaFormWithValidation>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex relative w-0 flex-1 bg-primary-500 justify-center items-center">
      <div class="text-white max-w-md p-16 xl:max-w-xl">
        <h3 class=" text-4xl xl:text-5xl font-semibold">
          Nous vous souhaitons un bon retour sur Bridge <Emoji data="👋"></Emoji>
        </h3>
        <p class="text-xl xl:text-2xl mt-10 font-light ">
          Après votre connexion, vous pourrez de nouveau gérer vos dossiers de locataire, planifier
          vos visites, télécharger votre bail, accéder à des conseils d’experts et bien d’autres
          fonctionnalités encore !
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useLoginMutation } from '@/generated/graphql';
import { ErrorResponse } from 'apollo-link-error';
import { useRoute, useRouter } from 'vue-router';
import * as yup from 'yup';

import { AuthErrorCode } from '@bridge-immo/core';
import { onLogin, redirectAfterLogin } from '@bridge-immo/common';

import { Input, Button, Emoji, Checkbox } from '@bridge-immo/ui';
import { SchemaFormWithValidation } from '@/components/SchemaFormWithValidation';

export default defineComponent({
  components: {
    Emoji,
    SchemaFormWithValidation,
    Button,
    Checkbox
  },
  setup() {
    const route = useRoute();
    const loginFormSchema = {
      email: {
        component: Input,
        label: 'Adresse Email',
        placeholder: 'jordan.belfort@bridge.immo',
        type: 'email',
        name: 'email',
        validations: yup
          .string()
          .required()
          .email(),
        autocomplete: 'email',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
               </svg>`
      },
      password: {
        component: Input,
        type: 'password',
        name: 'password',
        label: 'Mot de passe',
        placeholder: '••••••••••••••••',
        validations: yup
          .string()
          .required()
          .min(6),
        autocomplete: 'current-password',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
               </svg>`
      }
    };

    const loginForm = ref(null);
    const loginFormData = ref({ email: '', password: '', rememberMe: false });
    const { mutate: loginMutation, onDone, onError, loading } = useLoginMutation({});
    onDone(async result => {
      if (result?.data?.login.accessToken && result?.data?.login.user) {
        try {
          await onLogin(result?.data?.login.accessToken);
          redirectAfterLogin(result?.data?.login.user, route);
        } catch (e) {
          console.log(e);
        }
      }
    });

    onError(error => {
      const { graphQLErrors, networkError } = (error as unknown) as ErrorResponse;
      if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
          if (graphQLError?.extensions?.code === AuthErrorCode.EMAIL_ALREADY_EXISTS) {
            // TODO VL : handle login errors
          }
        }
      }

      if (networkError) {
        // TODO VL : display error
      }
    });
    const login = () => {
      loginMutation(loginFormData.value);
      (loginForm.value as any).resetForm({ values: { ...loginFormData.value, password: '' } });
    };

    const router = useRouter();
    const goToForgotPassword = () => {
      router.push({ name: 'forgot-password' });
    };
    const goToRegister = () => {
      window.location.assign(`${process.env.VUE_APP_ROOT_URL}/landlord/register`);
    };
    const goToLanding = () => {
      window.location.assign('https://www.bridge.immo');
    };

    return {
      loginForm,
      loginFormSchema,
      loginFormData,
      login,
      loading,
      goToForgotPassword,
      goToRegister,
      goToLanding
    };
  }
});
</script>
