
import { defineComponent, ref } from 'vue';
import { useLoginMutation } from '@/generated/graphql';
import { ErrorResponse } from 'apollo-link-error';
import { useRoute, useRouter } from 'vue-router';
import * as yup from 'yup';

import { AuthErrorCode } from '@bridge-immo/core';
import { onLogin, redirectAfterLogin } from '@bridge-immo/common';

import { Input, Button, Emoji, Checkbox } from '@bridge-immo/ui';
import { SchemaFormWithValidation } from '@/components/SchemaFormWithValidation';

export default defineComponent({
  components: {
    Emoji,
    SchemaFormWithValidation,
    Button,
    Checkbox
  },
  setup() {
    const route = useRoute();
    const loginFormSchema = {
      email: {
        component: Input,
        label: 'Adresse Email',
        placeholder: 'jordan.belfort@bridge.immo',
        type: 'email',
        name: 'email',
        validations: yup
          .string()
          .required()
          .email(),
        autocomplete: 'email',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
               </svg>`
      },
      password: {
        component: Input,
        type: 'password',
        name: 'password',
        label: 'Mot de passe',
        placeholder: '••••••••••••••••',
        validations: yup
          .string()
          .required()
          .min(6),
        autocomplete: 'current-password',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
               </svg>`
      }
    };

    const loginForm = ref(null);
    const loginFormData = ref({ email: '', password: '', rememberMe: false });
    const { mutate: loginMutation, onDone, onError, loading } = useLoginMutation({});
    onDone(async result => {
      if (result?.data?.login.accessToken && result?.data?.login.user) {
        try {
          await onLogin(result?.data?.login.accessToken);
          redirectAfterLogin(result?.data?.login.user, route);
        } catch (e) {
          console.log(e);
        }
      }
    });

    onError(error => {
      const { graphQLErrors, networkError } = (error as unknown) as ErrorResponse;
      if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
          if (graphQLError?.extensions?.code === AuthErrorCode.EMAIL_ALREADY_EXISTS) {
            // TODO VL : handle login errors
          }
        }
      }

      if (networkError) {
        // TODO VL : display error
      }
    });
    const login = () => {
      loginMutation(loginFormData.value);
      (loginForm.value as any).resetForm({ values: { ...loginFormData.value, password: '' } });
    };

    const router = useRouter();
    const goToForgotPassword = () => {
      router.push({ name: 'forgot-password' });
    };
    const goToRegister = () => {
      window.location.assign(`${process.env.VUE_APP_ROOT_URL}/landlord/register`);
    };
    const goToLanding = () => {
      window.location.assign('https://www.bridge.immo');
    };

    return {
      loginForm,
      loginFormSchema,
      loginFormData,
      login,
      loading,
      goToForgotPassword,
      goToRegister,
      goToLanding
    };
  }
});
